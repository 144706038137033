<template>
  <v-container v-if="hasPermission" fluid>
    <mex-sperm-spinner v-if="userViewLoading" :spinnerText="getUserLoadingSpinnerText" />
    <template v-else>
      <validation-observer ref="observer" v-slot="{ invalid }" @submit.prevent="saveChanges">
        <v-row justify="center">
          <v-col cols="12">
            <mex-heading content="System User"></mex-heading>
          </v-col>
        </v-row>
        <v-row class="stickyHeader" justify="center">
          <v-col cols="12">
            <mex-sheet  color="background" rounded sheetPadding="2">
              <v-row>
                <v-col cols="4"></v-col>
                <v-col justify="center" cols="4">
                  <v-autocomplete
                    v-model="selectedSystemUser"
                    :items="systemUsers"
                    hide-details
                    item-text="username"
                    item-value="SystemUserID"
                    outlined
                    dense
                    @change="selectionChanged"
                  />
                </v-col>
                <v-col justify="end" cols="4">
                  <v-row v-if="selectedSystemUser && !userViewLoading" justify="end">
                    <v-slide-x-transition>
                      <v-col v-if="editMode" cols="1">
                        <mex-btn icon="mdi-content-save" iconOnly @click="saveChanges" :disabled="invalid" />
                      </v-col>
                    </v-slide-x-transition>
                    <v-slide-x-transition>
                      <v-col v-if="editMode" cols="1">
                        <mex-btn icon="mdi-undo" iconOnly @click="getUserViewData" />
                      </v-col>
                    </v-slide-x-transition>
                    <v-col cols="1">
                      <mex-btn
                        v-if="writeUsers"
                        :color="editMode ? 'primary' : ''"
                        icon="mdi-pencil"
                        iconOnly
                        @click="editMode = true"
                      />
                    </v-col>
                    <v-col align-self="center" cols="1">
                      <mex-btn icon="mdi-refresh" iconOnly @click="getUserViewData" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </mex-sheet>
          </v-col>
        </v-row>
        <mex-sperm-spinner v-if="userDataLoading" :spinnerText="getUserLoadingSpinnerText" />
        <template v-else>
          <v-row justify="center">
            <v-col cols="9">
              <mex-card v-if="selectedSystemUser" icon="mdi-account-edit">
                <v-row justify="center">
                  <v-col align-self="center" cols="auto">
                    <mex-p class="mb-0" content="Registration completed:" />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-icon v-if="!registrationValid" color="error">mdi-lock-clock</v-icon>
                    <v-icon v-else-if="registrationTimeStamp" color="warning">
                      mdi-help-rhombus-outline
                    </v-icon>
                    <v-icon v-else color="success">mdi-check</v-icon>
                  </v-col>
                  <v-col cols="auto">
                    <v-divider vertical />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <mex-p class="mb-0" content="Created At:" />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <mex-p :content="getCreateDate" class="mb-0" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-row>
                      <mex-sheet v-if="!registrationValid" class="ma-4" color="error">
                        <v-row>
                          <v-col cols="1">
                            <v-icon large>mdi-lock-clock</v-icon>
                          </v-col>
                          <v-col cols="11">
                            <mex-p
                              alignment="center"
                              class="mb-0"
                              content="The registration time for this user has expired. You need to send a new registration mail."
                            />
                          </v-col>
                        </v-row>
                      </mex-sheet>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="8">
                        <validation-provider v-slot="{ errors }" name="Username" rules="required">
                          <v-text-field
                            v-model="username"
                            :error-messages="errors"
                            :readonly="!editMode"
                            autocomplete="off"
                            label="Username"
                            outlined
                            type="text"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="8">
                        <v-text-field
                          v-model="firstName"
                          :readonly="!editMode"
                          autocomplete="off"
                          label="First Name"
                          outlined
                          type="text"
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="8">
                        <v-text-field
                          v-model="lastName"
                          :readonly="!editMode"
                          autocomplete="off"
                          label="Last Name"
                          outlined
                          type="text"
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="8">
                        <validation-provider v-slot="{ errors }" name="E-Mail" rules="required|email">
                          <v-text-field
                            v-model="email"
                            :error-messages="errors"
                            :readonly="!editMode"
                            autocomplete="off"
                            label="E-Mail"
                            outlined
                            type="text"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="6">
                        <v-switch
                          v-model="active"
                          :readonly="!editMode"
                          color="secondary"
                          label="Active"
                          prepend-icon="mdi-alert-octagon"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="!active && registrationTimeStamp" justify="center">
                      <v-col cols="12">
                        <mex-sheet color="warning">
                          <v-row>
                            <v-col cols="1">
                              <v-icon>mdi-alert</v-icon>
                            </v-col>
                            <v-col>
                              <mex-p
                                alignment="center"
                                class="mb-0"
                                content="The user will not receive a registration email and will not be able to complete his registration until he is subsequently activated by an authorized user!"
                              />
                            </v-col>
                          </v-row>
                        </mex-sheet>
                      </v-col>
                    </v-row>
                    <v-row v-else-if="dbActive && !registrationValid" justify="center">
                      <mex-btn content="resend registration e-mail" @click="resendRegistrationEmail" />
                    </v-row>
                    <v-row v-else-if="!active && !registrationTimeStamp">
                      <v-col cols="12">
                        <mex-sheet color="warning">
                          <v-row>
                            <v-col cols="1">
                              <v-icon>mdi-alert</v-icon>
                            </v-col>
                            <v-col>
                              <mex-p
                                alignment="center"
                                class="mb-0"
                                content="The user is deactivated and cannot log into the system until reactivated by an authorized user."
                              />
                            </v-col>
                          </v-row>
                        </mex-sheet>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1">
                    <v-divider vertical></v-divider>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col>
                        <mex-p class="mb-0" content="User Roles:" />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col>
                        <role-treeview
                          :editMode="editMode"
                          :roles="roles"
                          :selectedRoles="selectedRoles"
                          displayFormat="checkbox"
                          @roleSelectionChanged="updateMyUserRoleArray"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </mex-card>
            </v-col>
          </v-row>
        </template>
      </validation-observer>
    </template>
    <save-request :showSaveRequest="showSaveRequest" @closeSaveRequest="showSaveRequest = false" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import RoleTreeview from "../../components/LicSrvComponents/RoleTreeview.vue";
import SystemUsersService from "../../services/systemUsers.service";
import SystemRolesService from "../../services/systemRoles.service";
import requiredPermissions from "../../requiredPermissions";
import keyListenerManager from "../../functions/keyListenerManager";
import SaveRequest from "../../components/LicSrvComponents/SaveRequest.vue";

export default {
  name: "EditUser.vue",
  components: {
    RoleTreeview,
    SaveRequest
  },
  computed: {
    ...mapGetters("sysAuth", ["isLoggedIn", "getUser", "getUserPermissions"]),
    getCreateDate() {
      return `${new Date(this.createdAt).toLocaleDateString()} | ${new Date(
        this.createdAt
      ).toLocaleTimeString()}`;
    },
    registrationValid() {
      if (this.registrationTimeStamp) {
        const actTimeStamp = !Date.now ? new Date().getTime() : Date.now();
        const timeSpanMS = actTimeStamp - this.registrationTimeStamp;
        const timeSpanMin = Math.floor(timeSpanMS / (1000 * 60));
        if (timeSpanMin > 10) {
          return false;
        }
      }
      return true;
    },
    writeUsers() {
      return this.getUserPermissions.includes("write_System_User Role Administration_User");
    },
    getUserLoadingSpinnerText() {
      return this.userViewLoadingTest;
    },
  },
  data() {
    return {
      roles: [],
      editMode: false,
      username: "",
      email: "",
      firstName: "",
      lastName: "",
      selectedRoles: [],
      active: true,
      createdAt: "",
      registrationTimeStamp: "",
      hasPermission: false,
      dbActive: false,
      systemUsers: [],
      selectedSystemUser: null,
      editModeAndSaveShortCutListener: null,
      boundEditModeAndSaveShortCutListener: null,
      userViewLoading: false,
      userDataLoading: false,
      userViewLoadingText: "",
      showSaveRequest: false,
    };
  },
  methods: {
    fetchAllSystemUser() {
      SystemUsersService.getUsers().then(response => {
        this.systemUsers = response.data.users;
        this.userViewLoading = false;
      });
    },
    async saveChanges() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$toast.error('Invalid parameters');
      } else {
        SystemUsersService.updateSystemUser(
          this.selectedSystemUser,
          this.username,
          this.email,
          this.firstName,
          this.lastName,
          this.active,
          this.selectedRoles
        )
          .then(response => {
            this.editMode = false;
            this.$toast.success("Update successful");
            this.$router.push({
              name: "ParamsReload",
              params: { name: "UserView", reloadParams: { id: response.data.editedSystemUserID } }
            });
          })
          .catch(err => {
            this.$toast.error(`Error while updating user: ${err.response.data.message}`);
          });
      }
    },
    getUserViewData() {
      this.editMode = false;
      this.userDataLoading = true;
      this.userViewLoadingText = "loading user data";
      // ---------------------- GET THE USER DATA --------------------
      SystemUsersService.getSystemUserByID(this.selectedSystemUser).then(userResponse => {
        this.username = userResponse.data.user.username;
        this.firstName = userResponse.data.user.firstName;
        this.lastName = userResponse.data.user.lastName;
        this.email = userResponse.data.user.email;
        this.active = userResponse.data.user.active;
        this.dbActive = userResponse.data.user.active;
        this.registrationTimeStamp = userResponse.data.user.registrationTimeStamp;
        this.createdAt = userResponse.data.user.createdAt;
        this.userViewLoadingText = "loading user roles";
        // ----------------------- GET THE USER ROLES ---------------------
        SystemRolesService.getRoles().then(rolesResponse => {
          this.roles = this.addValuesToRoles(rolesResponse.data.roles);
          SystemUsersService.getRolesByUserID(this.selectedSystemUser).then(userRolesResponse => {
            this.selectedRoles = userRolesResponse.data.roles;
            this.checkForSelectedRoles(this.roles);
            this.userDataLoading = false;
          })
          .catch(err => {
            this.userDataLoading = false;
            this.$toast.error(err.response.data.message);
          });
        })
        .catch(err => {
          this.userDataLoading = false;
          this.$toast.error(err.response.data.message);
        });
      })
      .catch(err => {
        this.userDataLoading = false;
        this.$toast.error(err.response.data.message);
      });
    },
    updateMyUserRoleArray(value) {
      this.selectedRoles = value;
    },
    checkForSelectedRoles(roles) {
      for (let i = 0; i < roles.length; i++) {
        if (this.selectedRoles.includes(roles[i].SystemRoleID)) {
          roles[i].checked = true;
          this.disableChildrenRoles(roles[i].children);
        } else if (roles[i].children.length !== 0) {
          this.checkForSelectedRoles(roles[i].children);
        }
      }
    },
    disableChildrenRoles(roles) {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].children.length !== 0) {
          this.disableChildrenRoles(roles[i].children);
        }
        roles[i].disabled = true;
      }
    },
    addValuesToRoles(roles) {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].children.length !== 0) this.addValuesToRoles(roles[i].children);
        roles[i].disabled = false;
        roles[i].checked = false;
      }
      return roles;
    },
    resendRegistrationEmail() {
      SystemUsersService.resendRegistrationEmail(this.selectedSystemUser)
        .then(response => {
          this.$toast.success("Registration e-mail successfully send");
          this.$router.push({
            name: "ParamsReload",
            params: { name: "UserView", reloadParams: { id: response.data.SystemUserID } }
          });
        })
        .catch(err => {
          this.$toast.error(
            `Error while trying to resend registration e-mail: ${err.response.data}`
          );
        });
    },
    selectionChanged(value) {
      this.selectedSystemUser = value;
    },
    manageKeyListener() {
      keyListenerManager.editModeAndSaveShortCut(
        this,
        document,
        "editModeAndSaveShortCutListener",
        "boundEditModeAndSaveShortCutListener",
        "editMode",
        "getUserViewData",
        "saveChanges"
      );
    }
  },
  watch: {
    selectedSystemUser: {
      handler() {
        this.getUserViewData();
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.boundEditModeAndSaveShortCutListener);
  },
  created() {
    this.userViewLoading = true;
    this.userViewLoadingText = 'loading users';

    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.userView, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchAllSystemUser();

          if (this.$route.params.id) {
            this.selectedSystemUser = parseInt(this.$route.params.id, 10);
          }

          if (this.getUserPermissions.includes("write_System_User Role Administration_User")) {
            this.manageKeyListener();
          }
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  }
};
</script>

<style scoped></style>
